body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  width: 95%
}

.App{
    padding-bottom: 10vh;
    height: 100%;
    width: 100vw;
}

body{
    background: black;
}

code {
    /* overflow-x: auto; */
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}


table{
    width:100%; 
    border-collapse:collapse; 
    border:#4e95f4 1px solid;
}
table td{ 
    padding:7px; border:#4e95f4 1px solid;
}
/* provide some minimal visual accomodation for IE8 and below */
table tr{
    background: #b8d1f3;
}
/*  Define the background color for all the ODD background rows  */
table tr:nth-child(odd){ 
    background: #b8d1f3;
}
/*  Define the background color for all the EVEN background rows  */
table tr:nth-child(even){
    background: #dae5f4;
}
