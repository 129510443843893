.App{
    padding-bottom: 10vh;
    height: 100%;
    width: 100vw;
}

body{
    background: black;
}

code {
    /* overflow-x: auto; */
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}


table{
    width:100%; 
    border-collapse:collapse; 
    border:#4e95f4 1px solid;
}
table td{ 
    padding:7px; border:#4e95f4 1px solid;
}
/* provide some minimal visual accomodation for IE8 and below */
table tr{
    background: #b8d1f3;
}
/*  Define the background color for all the ODD background rows  */
table tr:nth-child(odd){ 
    background: #b8d1f3;
}
/*  Define the background color for all the EVEN background rows  */
table tr:nth-child(even){
    background: #dae5f4;
}